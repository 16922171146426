import axios from 'axios';

let userdata = JSON.parse(localStorage.getItem('pinnacle_admin'));
let token;
if(userdata && userdata.token!='' && userdata.token!=undefined){
  token = userdata.token;
}
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common = {
  "Authorization": "Bearer "+token,
  "Accept" : 'application/json'
};
axios.interceptors.response.use(response => {
  return response;
}, error => {
 console.log(error)
 return error;
});
export default axios
// export const HTTP = axios.create({
//   baseURL: `http://127.0.0.1:3334/`,
//   headers: {
//     Authorization: 'Bearer '+token
//   }
// })