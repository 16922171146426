import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
let opts = {
  theme: {
    themes : {
      light: {
        primary: '#1a4476',
        secondary: '#80bc40',        
        accent: '#8c9eff',
        error: '#B71C1C'
      },
    }
  }
};
export default new Vuetify(opts);
